import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const DeathGodPage = () => {
  return (
    <Layout>
        <h1 className="center">Death God, Type I</h1>
        <h2>Field Notes</h2>
        <center><Image src="deathgod.png" alt="Drawing" /></center>
    </Layout>
  )
}

export default DeathGodPage